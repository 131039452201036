<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}

.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Update Clearing</h4>
    </span>

    <div class="vx-row mb-2">
      <div class="vx-col w-full mb-2">
        <label class="vs-input--label">Invoice</label>

        <multiselect
          class="selectExample"
          v-model="create.invoice"
          :options="option.invoice"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          :internal-search="false"
          placeholder=" Type to search"
          track-by="ID"
          label="Code"
          @search-change="getOptionInvoice"
          @select="selectedInvoice"
        >
        </multiselect>
      </div>
      <div v-if="!globalIsEmptyObject(create.invoice)" class="vx-col w-full">
        <vs-card>
          <div slot="header">
            <div class="vx-row" style="padding-left: 10px">
              <div style="width: 80%">
                <h6 style="left-margin: 1%">
                  {{ create.invoice.Code }}
                </h6>
              </div>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full">
              Date : {{ globalDateFormat(create.invoice.Date) }}<br />
              Due Date : {{ globalDateFormat(create.invoice.DueDate) }}<br />
              Posting Date : {{ globalDateFormat(create.invoice.PostingDate)
              }}<br />
              Created Date : {{ globalDateFormat(create.invoice.CreatedAt)
              }}<br />
              Sales Order Code : {{ create.invoice.SalesOrderCode }}<br />
              Invoice Reference Code : {{ create.invoice.InvoiceReferenceCode
              }}<br />
              Note : {{ create.invoice.Notes }}
            </div>
            <div class="vx-col sm:w-1/3 w-full">
              Subtotal : {{ priceFormat(create.invoice.Subtotal) }}<br />
              Discount : {{ priceFormat(create.invoice.Discount) }}<br />
              Subtotal Charge : {{ priceFormat(create.invoice.SubtotalCharge)
              }}<br />
              Other Cost : {{ priceFormat(create.invoice.OtherCost) }}<br />
              Tax : {{ priceFormat(create.invoice.TaxAmount) }}<br />
              Total : {{ priceFormat(create.invoice.Total) }}<br />
              Remaining : {{ priceFormat(create.invoice.Remaining) }}
            </div>
            <!-- {{ create.invoice }} -->
          </div>
        </vs-card>
      </div>

      

      <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png .pdf"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>
      <!-- display: block -->
      <vs-divider style="width: 100%; margin-left: 2%">
        List Attachment
      </vs-divider>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <table class="vs-table vs-table--tbody-table">
          <template v-for="(tr, i) in fileAttachment">
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.NameFile"
            >
              <td class="td vs-table--td">{{ tr.NameFile }}</td>

              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click.stop="downloadFileAwsS3(tr.PathFile)"
                  />
                </vx-tooltip>
              </td>

              <td class="td vs-table--td">
                <template>
                  <vx-tooltip text="Delete">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click.stop="() => { fileAttachment.splice(i, 1); }"
                    />
                  </vx-tooltip>
                </template>
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>

    <vs-divider style="width: 100%; margin-left: 2%">Adjustment</vs-divider>
    <div class="vx-row mb-2">
      <div class="vx-col sm:w-1/2 w-full">
        <vs-input
          name="adjustmentValue"
          v-model="create.adjustmentValue"
          v-validate="`required|between:0,${maxAdjustment}`"
          class="w-full input-readonly"
          @keypress="globalIsNumber($event)"
          @keyup="
            create.adjustmentValue = formatPrice(
              create.adjustmentValue.toString()
            )
          "
          label="Adjustment Value"
          placeholder="Adjustment Value"
          :danger="errors.has('adjustmentValue')"
          readonly
        />
        <!-- display: block -->
        <span
          v-show="errors.has('adjustmentValue')"
          class="text-danger text-sm help is-danger"
        >
          {{ errors.first("adjustmentValue") }}
        </span>
      </div>

      <div class="vx-col sm:w-1/2 w-full">
        <label class="vs-input--label">Posting Date</label>
        <datepicker
          :class="{
            'w-full': true,
            'border-danger': errors.has('postingDate'),
          }"
          v-model="create.postingDate"
          v-validate="'required'"
          name="postingDate"
          placeholder="Posting Date"
          :disabled-dates="{ from: new Date() }"
          :danger="errors.has('postingDate')"
        >
        </datepicker>
        <!-- display: block -->
        <span
          v-show="errors.has('postingDate')"
          class="text-danger text-sm help is-danger"
        >
          {{ errors.first("postingDate") }}
        </span>
      </div>
    </div>
    <div v-for="(x,k) in inputs" :key="k">
      <div class="vx-row mb-2">
        <div class="vx-col sm:w-1/3 w-full">
          <vs-input
            name="adjustmentValue"
            v-model="x.additional_value"
            v-validate="`required|between:0,${maxAdjustment}`"
            class="w-full"
            @keypress="globalIsNumber($event)"
            @keyup="
              x.additional_value = formatPrice(
                x.additional_value.toString()
              )
            "
            label="Adjustment Value"
            placeholder="Adjustment Value"
            @input="adjustmentValue(k)"
            
          />
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <label class="vs-input--label">COA</label>
          <multiselect
            name="coa"
            v-validate="'required'"
            :preselectFirst="true"
            :showNoOptions="false"
            :class="{ 'border-danger': errors.has('coa') }"
            v-model="x.coa"
            :options="option.coa"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            :limit="4"
            :internal-search="false"
            :show-labels="false"
            @search-change="getOptionCoa"
            placeholder="Type to search"
            noOptions="Type to search"
            track-by="Code"
            label="Code"
            @input="onChangeCoa(k)"
          >
            <template slot="singleLabel" slot-scope="dt">
              <span class="option__desc">
                <span class="option__title"
                  >{{ dt.option.Code }} - {{ dt.option.Name }}</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="dt">
              <div class="option__desc">
                <span class="option__title"
                  >{{ dt.option.Code }} - {{ dt.option.Name }}</span
                >
              </div>
            </template>
          </multiselect>
          <!-- display: block -->
          <span
            v-show="errors.has('coa')"
            class="text-danger text-sm help is-danger"
          >
            {{ errors.first("coa") }}
          </span>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <label class="vs-input--label">Cost Center</label>
          <multiselect
            :class="{ 'border-danger': errors.has('costCenter') }"
			:name="'costCenter' + k"
            v-validate="x.cost_center_required"
            v-model="x.cost_center"
            :options="option.costCenter"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :show-labels="false"
            :max-height="160"
            :limit="4"
            placeholder=" Type to search"
            track-by="Name"
            label="Name"
          >
            <template slot="singleLabel" slot-scope="dt">
              <span class="option__desc">
                <span class="option__title">{{ dt.option.Name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="dt">
              <div class="option__desc">
                <span class="option__title">{{ dt.option.Name }}</span>
              </div>
            </template>
          </multiselect>
          <!-- display: block -->
          <span
            v-show="errors.has('costCenter')"
            class="text-danger text-sm help is-danger"
          >
            {{ errors.first("costCenter") }}
          </span>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col sm:w-1/2 w-full">
          <label class="vs-input--label">Reason</label>
          <vs-textarea
            :class="{
              'w-full vs-input-primary': true,
              'border-danger': errors.has('reason'),
            }"
            name="reason"
            v-validate="'required'"
            v-model="x.reason"
          />
          <!-- display: block -->
          <span
            v-show="errors.has('reason')"
            class="text-danger text-sm help is-danger"
          >
            {{ errors.first("reason") }}
          </span>
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <div class="mt-10">
            <div class="vx-row mb-2">
              <div class="vx-col sm:w-1/4" v-show="k || (!k && inputs.length > 1)">
                <vs-button style="margin-right: 5px;" size="small" @click="removeField(k)" icon-pack="feather" icon="icon-minus">
                </vs-button>
              </div>
              <div class="vx-col sm:w-1/4" v-show="k == inputs.length - 1">
                <vs-button style="margin-right: 5px;" size="small" @click="addField(k)" icon-pack="feather" icon="icon-plus">
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col sm:w-1/2 w-full">
        <vs-input
          class="w-full input-readonly"
          label="Control Value"
          name="Control Value"
          v-model="create.controlValue"
          readonly
        />
      </div>
      <!-- <span class="text-danger text-sm" v-show="errors.has('ControlValue')">{{errors.first("ControlValue")}}</span> -->
    </div>

    <div class="vx-col w-full">
      <br />
      <!-- display: block -->
      <vs-button class="mb-2" v-on:click="handleSubmit">
        Submit
      </vs-button>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
// import { Validator } from 'vee-validate';

export default {
    components: {
        Datepicker,
    },
    props: {
		selected: Object,
    },
    created() { },
    data() {
        return this.initialState();
    },
    methods: {
        selectedInvoice(arg) {
            let remaining = parseFloat(arg.Remaining).toFixed(2)
            this.maxAdjustment = remaining
        },
        initialState() {
            return {
                fileAttachment: [],
                search: "",
                maxAdjustment: 0, 
                option: {
                    invoice: [],
                    coa: [],
                    costCenter: [],
                },
                adjustment:{},
                adjustmentLine:{},
                additionalCoa: [{
                  list_id: null,
                  additional_value: null,
                  cost_center_id: null,
                  cost_center_code: null,
                  cost_center_name: null,
                  coa_id: null,
                  coa_code: null,
                  coa_name: null,
                  reason: null,
                }],
                inputs: [{
                  additional_value: null,
                  cost_center: null,
                  cost_center_required: false,
                  coa: null,
                  reason: null,
                }],
                create: {
                    invoice: {},
                    adjustmentValue: null,
                    postingDate: null,
                    coa: null,
                    reason: "",
                    costCenter: null,
                    controlValue: null,
                },
                customNotif: {
                    custom: {
                        adjustmentValue: {
                            required: "Adjustment value is empty",
                            between: (field, max) => `Adjustment value field must be between 0 and ${max.toString().split(",")[1]}`
                        },
                        reason: {
                            required: () => "Reason is empty",
                        },
                        costCenter: {
                            required: "Coct center value is empty",
                        },
                        postingDate: {
                            required: "Posting date value is empty",
                        },
                    },
                },
            };
        },
        mappingAttachment(){
          this.adjustment.Attachment.map(v => {
            this.fileAttachment.push({
              ListID: v.ListID,
              NameFile: v.NameFile,
              PathFile: v.PathFile,
            });
          })
          // console.log(this.fileAttachment);
        },
        handleAttachment() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files;
                // this.fileAttachment = [];
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    // console.log(this.file[i]);
                    // this.uploadData(i);
                    this.fileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                }
                document.querySelector("#fileInput").value = "";
            } else {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select file to import.",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
            }
        },
        formatPrice(angka, prefix = "") {
            return angka
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        paramUpload(array) {
            var form = new FormData();
            form.append("id", this.selected.ID);
            form.append("file", this.file[array]);
            return form;
        },
        uploadAttachmentAfter(i, id) {
            // this.$vs.loading();
            if (this.fileAttachment.length == 0) {
                this.$emit("closeDetail", true);
                this.$vs.loading.close();
                return;
            }
            if (this.fileAttachment.length < i) {
                this.$emit("closeDetail", true);
                this.$vs.loading.close();
                return;
            }
            var form = new FormData();
            form.append("id", id);
            form.append("file", this.fileAttachment[i].File);
            this.$http
                .post("/api/v1/finance/adjustment/upload-file", form, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((resp) => {
                    this.uploadAttachmentAfter(++i, id);
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.uploadAttachmentAfter(++i, id);
                });
            // })
        },
        handleShow(file) {
            let objectURL;
            // console.log(file);
            objectURL = URL.createObjectURL(file.File);

            // link.download = file.name; // this name is used when the user downloads the file
            var href = objectURL;
            window.open(href, "_blank").focus();
        },
        handleDeleteAttachment(id) {
            // console.log(id);
            this.$vs.loading();
            var form = new FormData();
            form.append("list_id", id);
            form.append("id", this.selected.ID);
            this.$http
              .post("/api/v1/finance/adjustment/delete-file", form, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((resp) => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                  this.fileAttachment = this.fileAttachment.filter((v) => {
                    return v.ListID != id;
                  });
                  this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                } else {
                  this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                }
              })
              .catch((error) => {
                this.$vs.loading.close();
                // console.log(error);
              });
        },
        getOptionCostCenter() {
            this.$vs.loading();
            this.$http
                .get("/api/v1/master/cost-center", {
                    params: {
                        order: "name",
                        sort: "asc",
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        // this.option.costCenter = resp.data.records;
						let costCenter = [];
                        resp.data.records.map((v, i) => {
                            const option = {
                              ID: v.ID,
                              Name: v.Name,
                              Code: v.Code,
                            }
                            costCenter.push(option);
                        });
                        this.option.costCenter = costCenter;
                    } else {
                        this.$vs.notify({
                            title: "Error",
                            text: "Failed to get Cost Center option",
                            color: "danger",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    }
                    this.$vs.loading.close();
                });
        },
        getOptionCoa(query) {
            if (query.length <= 2) {
                return;
            }
            this.$vs.loading();
            this.$http
                .get("/api/v1/master/chart-of-account", {
                    params: {
                        search: query,
                        order: "code",
                        sort: "asc",
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        // var _this = this
                        this.option.coa = [];
                        let coa = [];
                        resp.data.records.map((v, i) => {
                            const option = {
                              ID: v.coa.ID,
                              Name: v.coa.Name,
                              Code: v.coa.Code,
                            }
                            coa.push(option);
                        });
                        this.option.coa = coa;
                        // console.log(this.option.coa, "coa");
                    } else {
                        this.$vs.notify({
                            title: "Error",
                            text: "Failed to get Coa option",
                            color: "danger",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    }
                    this.$vs.loading.close();
                });
        },
        getGeneralForm() {
            // this.getOptionCoa();
            this.getOptionCostCenter();
        },
        handleSubmit() {
            this.$validator.validateAll().then((result) => {
                // console.log(result);
                for (let i = 0; i < this.inputs.length; i++) {
					let unixTimestamp = Math.floor(new Date().getTime() / 1000);
					this.additionalCoa[i].list_id = unixTimestamp
					this.additionalCoa[i].additional_value = parseFloat(this.inputs[i].additional_value.toString().replace(/,/g, ""))
					if (this.inputs[i].cost_center) {
						this.additionalCoa[i].cost_center_id = this.inputs[i].cost_center.ID
						this.additionalCoa[i].cost_center_code = this.inputs[i].cost_center.Code
						this.additionalCoa[i].cost_center_name =this.inputs[i].cost_center.Name
					}
					this.additionalCoa[i].coa_id = this.inputs[i].coa.ID
					this.additionalCoa[i].coa_code = this.inputs[i].coa.Code
					this.additionalCoa[i].coa_name = this.inputs[i].coa.Name
					this.additionalCoa[i].reason = this.inputs[i].reason
                }
                if (result) {
                    this.$vs.loading();
                    const params = {
                        posting_date: moment(
                            this.create.posting_date == "" ? new Date() : this.create.postingDate
                        ),
						id: this.create.ID,
                        invoice: this.create.invoice,
                        adjustment_value: parseFloat(this.create.adjustmentValue.toString().replace(/,/g, "")),
                        coa: this.create.coa,
                        transaction_type: '01',
                        source_of: 0,
                        reason: this.create.reason,
                        cost_center: this.create.costCenter,
                        control_value: parseFloat(this.create.controlValue.toString().replace(/,/g, "")),
                        additional_coa: this.additionalCoa,
                    };
                    this.$http
                        .post("/api/v1/finance/adjustment/update", params)
                        .then((result) => {
                            this.$vs.loading.close();
                            if (result.code == 200) {
                                // this.handleClose()
                                this.$vs.notify({
                                    title: "Success",
                                    text: "",
                                    color: "success",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check",
                                });
                                this.uploadAttachmentAfter(0, result.data.AdjustmentFinance.ID);
                            } else {
								this.$vs.notify({
									title: "Error",
                                    text: result.message,
                                    color: "danger",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check",
                                });
                            }
							this.handleClose();
                        })
                        .catch((e) => { });
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: this.errors.all().join("\n"),
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                    });
                }
            }); 
        },
        handleClose() {
            window.scrollTo(0, 0);
            this.$emit("closeDetail", true);
        },
        getOptionInvoice(query) {
            if (query.length <= 2) {
                return;
            }
            this.$vs.loading();
            this.$http
                .get("api/v1/invoice/data-table", {
                    params: {
                        search: query,
                        length: 10,
                        page: 1,
                        order: "asc",
                        sort: "code",
						tax_type_id: 2,
                        // territory_id: this.selected.territory.ID,
                        status: 3,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.option.invoice = resp.data.invoices;
                        // console.log(this.option.invoice);
                    } else {
                        this.$vs.notify({
                            title: "Error",
                            text: "Failed to get Invoice option",
                            color: "danger",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    }
                    this.$vs.loading.close();
                });
        },
        selectedStep() {
          const additional = this.adjustment.AdditionalCoa
          for (let i = 1; i < additional.length; i++) {
            this.inputs.push({
              additional_value: null,
              cost_center: null,
              coa: null,
              reason: null,
            })
            this.additionalCoa.push({
              list_id: null,
              additional_value: null,
              cost_center_id: null,
              cost_center_code: null,
              cost_center_name: null,
              coa_id: null,
              coa_code: null,
              coa_name: null,
              reason: null,
            })
          }

          for (let i = 0; i < additional.length; i++) {
            const coa = {
              ID: additional[i].coa_id,
              Name: additional[i].coa_code,
              Code: additional[i].coa_name,
            }
            this.inputs[i].coa = coa
            this.option.coa = []
            this.option.coa.push(coa) 
      
            const costCenter = {
              ID: additional[i].cost_center_id,
              Name: additional[i].cost_center_name,
              Code: additional[i].cost_center_code,
            }
      
            this.inputs[i].cost_center = costCenter
            
            this.inputs[i].reason = additional[i].Reason
            this.inputs[i].additional_value = this.formatPrice(additional[i].additional_value)
          }
          this.create.ID = this.adjustment.ID 
          this.create.adjustmentValue = this.formatPrice(this.adjustmentLine[0].AdjustmentValue)
          this.create.postingDate = this.adjustment.PostingDate
        },
        findAdjustment(ID) {
            this.$vs.loading();
            this.$http
                .get(`api/v1/finance/adjustment/${ID}`)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.adjustment = resp.data.records;
                        this.adjustmentLine = resp.data.lines;
                        this.create.invoice = resp.data.invoice[0];
						this.selectedInvoice(this.create.invoice)
                        this.selectedStep()
                        this.mappingAttachment()
                    } else {
                        this.$vs.notify({
                            title: "Error",
                            text: "Failed to get Adjustment option",
                            color: "danger",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    }
                    this.$vs.loading.close();
                });
        },
      onChangeCoa(index) {
        
        if (this.inputs[index].coa.Code.slice(0,1) == "7") {

          let costCenter = this.option.costCenter.filter( v => v.Code == "OTH" )
          console.log(index, "OTH");

          console.log(costCenter[0], "costCenter[0]")
          // this.create.costCenter = costCenter[0]
          this.inputs[index].cost_center = costCenter[0]
          this.inputs[index].cost_center_required = true
		  
        } else {
			this.inputs[index].cost_center_required = false
		}
        console.log("index", this.inputs);
      },
      adjustmentValue() {
        let adjustment = 0
        for (let i = 0; i < this.inputs.length; i++) {
          adjustment += this.inputs[i].additional_value == null ? 0 : parseFloat(this.inputs[i].additional_value.toString().replace(/,/g, ""))
          // console.log("adjustment", adjustment);
        }

        // console.log("adjustment2", adjustment);
        this.create.adjustmentValue = isNaN(adjustment) ? null : this.formatPrice(adjustment.toFixed(2));
      },
      removeField(index) {
        this.inputs.splice(index, 1)
      },
      addField() {
        this.inputs.push({
          additional_value: null,
          cost_center: null,
          coa: null,
          reason: null,
        })
        this.option.coa = []
        console.log(this.option.coa)
        this.additionalCoa.push({
          list_id: null,
          additional_value: null,
          cost_center_id: null,
          cost_center_code: null,
          cost_center_name: null,
          coa_id: null,
          coa_code: null,
          coa_name: null,
          reason: null,
        })
      },
    },
    mounted() {
		this.findAdjustment(this.selected.ID)
        this.$validator.localize("en", this.customNotif);
        this.getGeneralForm();
    },
    computed: {},
    watch: {
        "create.adjustmentValue"() {
            let adjustment = this.create.adjustmentValue.toString().replace(/[^.\d]/g, "").toString()
            let controlValue = this.maxAdjustment - adjustment
            this.create.controlValue = this.formatPrice(parseFloat(controlValue.toString()).toFixed(2))
        },
		"selected.ID"() {
			console.log(this.selected,"watch")
			this.findAdjustment(this.selected.ID)
		}
    },
};
</script>
<style scoped>
.pagination {
  display: flex;

  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.border-danger {
  border: 1px solid rgba(var(--vs-danger), 10) !important;

  border-radius: 5px;
}
</style>
